import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { universoService } from '../services/universos/universo.service';
import { Router } from "@angular/router";
@Component({
  selector: 'app-agregar-universos',
  templateUrl: './agregar-universos.component.html',
  styleUrls: ['./agregar-universos.component.scss']
})
export class AgregarUniversosComponent implements OnInit {
  name;
  constructor(
    public toastr: ToastrService,
    private universe: universoService,
    public router: Router
  ) { }

  ngOnInit() {
  }
  addUniverso(){
    if(this.name){
      console.log(this.name);
      this.universe.createUniverse(this.name).then((res:any) =>{
        console.log(res);
        if(res.ok == true){
          this.toastr.success('Se creo universo ' + res.universo.name );
          this.router.navigate(['universos'])
        }
      })
    }else{
      this.toastr.error( "Debe Ingresar un nombre de universo");
    }
  }

}
