import { AdminApiService } from './../admin-api.service';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewContainerRef,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { BqAuthService } from '../services/bq-auth.service';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminPageComponent implements OnInit {
  public _showAlerts: boolean;
  public _showStats: boolean;
  public __sending: boolean;

  public _develStats: any;
  public _uploadMode: boolean;

  public _isDeveloper: boolean;

  constructor(
    private adminApi: AdminApiService,
    public toastr: ToastrService,
    vcr: ViewContainerRef,
    private bqAuth: BqAuthService,
  ) {
    this._isDeveloper = false;
  }

  ngOnInit() {
    this._showAlerts = false;
    this._uploadMode = false;
    this._showStats = true;
    this.__sending = false;
    this._isDeveloper = this.bqAuth.isDeveloper();
    // this.adminApi.getDeveloperStats().then(stats => {
    //   this._develStats = stats;
    // });
  }

  toggleAlerts() {
    this._showAlerts = !this._showAlerts;
    this._uploadMode = false;
    this._showStats = false;
  }

  updatePage() {}

  uploadQRCodes() {
    this._uploadMode = true;
    this._showStats = false;
    this._showAlerts = false;
  }

  uploadCSV(ev) {
    this.__sending = true;
    if (!ev || !ev.target || !ev.target.files[0]) {
      this.toastr.error('Debe seleccionar un fichero.');
      return;
    }

    console.log('Uploading CSV %c%s', 'color: green', ev.target.files[0].name);

    if (ev.target.files[0].type !== 'text/csv') {
      console.log(
        'Error uploading CSV, is not a CSV current file type: %c%s',
        'color: red',
        ev.target.files[0].type,
      );
      this.toastr.error(
        'El fichero debe ser del tipo CSV, entre en Excel y exportelo en el formato correcto.',
      );
      return;
    }

    let reader = new FileReader();
    let file = ev.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      let tempFile = {
        filename: file.name,
        type: file.type,
        data: reader.result.split(',')[1],
      };

      this.adminApi
        .uploadQRCodes({ csvdata: tempFile })
        .then(res => {
          this.toastr.success(
            'Los codigos se estan generando en background, en breve estarán disponibles.',
          );
          setTimeout(() => {
            location.reload();
          }, 500);
          this._uploadMode = false;
        })
        .catch(err => {
          console.dir(err);
        });
    };
  }

  disabledAction() {
    this.toastr.warning(
      'La subida de ficheros NPS no esta disponible en estos momentos.',
    );
    return;
  }
}
