import { BaseApiService } from '../services/base-api.service';
import { AdminApiService } from './../admin-api.service';
import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { StoreApiService } from '../services/store-api.service';
import { CommonApiService } from '../services/common-api.service';

@Component({
  selector: "app-survey-list",
  templateUrl: "./survey-list.component.html",
  styleUrls: ["./survey-list.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SurveyListComponent implements OnInit {
  public _surveyList: any;
  public _search: any;
  public _currentSearch: any;
  public _totalSurveys: number;
  public _itemsByPage: number;
  public _currentPage: number;
  public _totalPages: number;
  public _storeList: any;
  public _deviceList: any;
  public _promoList: any;

  @Output() onSurveyClicked = new EventEmitter<string>();

  constructor(
    private adminApi: AdminApiService,
    private storeApi: StoreApiService,
    private baseApi: BaseApiService
  ) {
    this._search = {
      start: null,
      end: null,
      store: "any",
      device: "any",
      promotion: "any"
    };
    this._currentSearch = {};
    this._totalSurveys = 0;
    this._currentPage = 0;
    this._itemsByPage = 10;
    this._totalPages = 1;
    this._surveyList = [];
    this._storeList = [];
    this._deviceList = [];
  }

  updatePage(page: number) {
    this._currentPage = page;
    this.loadSurveys();
  }

  loadCommonData() {
    this.storeApi
      .getStoreList()
      .then(stores => {
        this._storeList = stores;
      })
      .catch(sErr => {
        this._storeList = [];
        console.log("Has been a error loading stores.");
      });

    this.baseApi
      .getDeviceList()
      .then((devices: any) => {
        this._deviceList = devices.items;
      })
      .catch(sErr => {
        this._deviceList = [];
        console.log("Has been a error loading devices.");
      });

    this.loadSurveys();
  }

  ngOnInit() {
    this.loadCommonData();
  }

  showDetails(surveyId: string) {
    this.onSurveyClicked.emit(surveyId);
  }

  doSearch() {
    this._currentSearch = this._search;
    this.loadSurveys();
  }

  doClear() {
    this._search = {
      start: null,
      end: null,
      store: "any",
      device: "any",
      promotion: "any"
    };
    this._currentSearch = {};
    this._totalSurveys = 0;
    this._currentPage = 0;
    this._itemsByPage = 10;
    this._totalPages = 1;
    this._surveyList = [];
    this.loadSurveys();
  }

  loadSurveys() {
    this.adminApi
      .listSurveys(this._currentPage, this._itemsByPage, this._currentSearch )
      .then((surveys: any) => {
        this._surveyList = surveys.items;
        this._totalSurveys = surveys.total;
        this._totalPages = Math.floor(surveys.total / this._itemsByPage) + 1;
      });
  }
}
