import { MailTemplate } from '../services/bqdata-model';
import { AdminApiService } from './../admin-api.service';
import { Component, OnInit, ViewEncapsulation, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: "app-mail-template-editor",
  templateUrl: "./mail-template-editor.component.html",
  styleUrls: ["./mail-template-editor.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class MailTemplateEditorComponent implements OnInit, OnChanges {
  public _editorOptions: Object = {
    charCounterCount: true,
    placeholder: "Introduzca un texto",
    language: "es",
    heightMin: 350,
    toolbarButtons: [
      "bold",
      "italic",
      "underline",
      "alert",
      "align",
      "fontFamily",
      'fontSize',
      'color',
      'paragraphFormat',
      'insertImage',
      'insertLink',
      'formatOL',
      'formatUL',
      'emoticons',
      'fullscreen',
      'print'
    ]
  };

  private _templateId: string;
  public _currentTemplate: MailTemplate;

  @Output() onEditorEnd = new EventEmitter<boolean>();

  @Input()
  set templateId(templateId: string) {
    this._templateId = templateId;
  }
  get templateId(): string {
    return this._templateId;
  }

  constructor(private adminApi: AdminApiService) {}

  ngOnInit() {
    this._currentTemplate = new MailTemplate();
  }

  ngOnChanges() {
    console.log('Loading email template: %s', this._templateId);
    this.adminApi.getEmailTemplate(this._templateId).then((tpl: MailTemplate) => {
      this._currentTemplate = tpl;
    });
  }

  updateTemplate() {

  }

  goBack(){
    this.onEditorEnd.emit(false);
  }
}
