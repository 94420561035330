import { Component, OnInit } from '@angular/core';
import  { Router } from '@angular/router'
import { universoService } from '../services/universos/universo.service';
@Component({
  selector: 'app-universos',
  templateUrl: './universos.component.html',
  styleUrls: ['./universos.component.scss']
})
export class UniversosComponent implements OnInit {
  list;
  constructor(
    public router : Router,
    private universe: universoService,
  ) { }

  ngOnInit() {
    this.universe.getUniverses().then( res =>{
      console.log(res);
      this.list = res;
    })
  }
  add(){
    this.router.navigate(['agregar-universos']);
  }
}
