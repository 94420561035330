import { Component, OnInit } from '@angular/core';
import { Router} from "@angular/router"
import { universoService } from '../services/universos/universo.service';
@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {
  list;
  constructor( public router : Router ,   private universe: universoService,)  { }

  ngOnInit() {
    this.list = [];
    this.getBrands();
  }
  add(){
    this.router.navigate(["/agregar-marca"])
  }
  getBrands(){
   this.universe.getBrands().then(res =>{
     console.log(res);
     this.list = res;
   })
  }
  delete(id){
    console.log('borrar' , id);
    this.universe.deleteBrand(id).then(res =>{
      console.log(res);
      this.ngOnInit();
    })
  }
  edit(item:any){
    let data = JSON.stringify(item);
    this.router.navigate(['edit-marca', { marca : data }] );
  }
}
