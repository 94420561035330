import { AdminApiService } from "../admin-api.service";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ViewContainerRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { debug } from "util";

@Component({
  selector: "app-csv-e-upload-page",
  templateUrl: "./csv-e-upload-page.component.html",
  styleUrls: ["./csv-e-upload-page.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class CsveUploadPageComponent implements OnInit {
  public currentFile: any;
  public __sending: boolean;
  public storeList: any;
  public _totalStores: number;
  public _itemsByPage: number;
  public _currentPage: number;
  public _totalPages: number;
  public seleccion;


  constructor(
    public toastr: ToastrService,
    vcr: ViewContainerRef,
    private adminApi: AdminApiService
  ) {}

  ngOnInit() {
    this.storeList = [];
    this._totalStores = 0;
    this._currentPage = 0;
    this._itemsByPage = 15;
    this._totalPages = 1;
    this.loadStores();
    
  }
  loadStores() {
    this.adminApi.listStores( this._currentPage, this._itemsByPage, {} ).then((res: any) => {
      this.storeList = res.items;
      this._totalStores = res.total;
      this._totalPages = Math.floor(res.total / this._itemsByPage) + 1;
    }); 
  }
  
  uploadCSV(ev) {
    this.__sending = true;
    if (!ev || !ev.target || !ev.target.files[0]) {
      this.toastr.error("Debe seleccionar un fichero.");
      return;
    }

    console.log("Uploading CSV %c%s", "color: green", ev.target.files[0].name);

    // if (ev.target.files[0].type !== 'text/csv') {
    //   console.log(
    //     'Error uploading CSV, is not a CSV current file type: %c%s',
    //     'color: red',
    //     ev.target.files[0].type
    //   );
    //   this.toastr.error(
    //     'El fichero debe ser del tipo CSV, entre en Excel y exportelo en el formato correcto.'
    //   );
    //   return;
    // }

    let reader = new FileReader();
    let file = ev.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => { 
      let tempFile = {
        filename: file.name,
        type: file.type,
        data: reader.result.split(",")[1]
      };

      let formData: FormData = new FormData();

      formData.append("csvfile", file, file.name);
      console.log(formData);
      this.adminApi.uploadNPSECSV(formData , this.seleccion)
        .then(res => {
          this.toastr.success(
            "El fichero se ha subido correctamente, en cuanto haya sido procesado se mostrará en la pantalla de NPS."
          );
        })
        .catch(err => {
          this.toastr.error(err, "Ha habido un error al subir el archivo.");
        });
    };
  }
}
