import { BqAuthService } from '../services/bq-auth.service';
import {
  Component,
  OnInit,
  OnChanges,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { AdminApiService } from '../admin-api.service';
import * as moment from 'moment';

@Component({
  selector: 'app-npssurvey-list',
  templateUrl: './npssurvey-list.component.html',
  styleUrls: ['./npssurvey-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NpssurveyListComponent implements OnInit, OnChanges {
  public _surveyList: any;
  private _promoId: string;

  public _search: any;
  public _currentSearch: any;

  public _requestedStartDate: any;
  public _requestedEndDate: any;

  public _totalSurveys: number;
  public _itemsByPage: number;
  public _currentPage: number;
  public _totalPages: number;
  public _isDeveloper: boolean;

  @Output()
  onTraceMailAction = new EventEmitter<string>();

  @Input()
  get promotionId() {
    return this._promoId;
  }

  set promotionId(promoId: string) {
    this._promoId = promoId;
  }

  constructor(
    private adminApi: AdminApiService,
    private bqAuth: BqAuthService,
  ) {
    this._currentSearch = {};
    this._totalSurveys = 0;
    this._currentPage = 0;
    this._itemsByPage = 10;
    this._totalPages = 1;
    this._surveyList = null;
    this._search = {};
    this._isDeveloper = false;
  }

  ngOnInit() {
    this.clearAll();
  }

  ngOnChanges() {}

  loadSurveys() {
    this._search = {
      startDate: `${this._requestedStartDate.day}/${
        this._requestedStartDate.month
      }/${this._requestedStartDate.year}`,
      endDate: `${this._requestedEndDate.day}/${this._requestedEndDate.month}/${
        this._requestedEndDate.year
      }`,
    };

    if (this._promoId) {
      this._search.promotion = this._promoId;
    }

    this.adminApi
      .listNPSSurveys(this._currentPage, this._itemsByPage, this._search)
      .then((surveys: any) => {
        this._surveyList = surveys.items;
        this._totalSurveys = surveys.total;
        this._totalPages = Math.floor(surveys.total / this._itemsByPage) + 1;
      });
  }

  updatePage(page: number) {
    this._currentPage = page;
    this.loadSurveys();
  }

  traceEmail(surveyId: string) {
    this.onTraceMailAction.emit(surveyId);
  }

  clearAll() {
    this._isDeveloper = this.bqAuth.isDeveloper();
    this._currentSearch = {};
    this._totalSurveys = 0;
    this._currentPage = 0;
    this._itemsByPage = 10;
    this._totalPages = 1;
    this._surveyList = null;
    this._search = {};

    const start = moment()
      .subtract(1, 'months')
      .startOf('month');
    const end = moment()
      .subtract(1, 'months')
      .endOf('month');

    this._requestedStartDate = {
      day: parseInt(start.format('D'), 10),
      month: parseInt(start.format('MM'), 10),
      year: parseInt(start.format('YYYY'), 10),
    };

    this._requestedEndDate = {
      day: parseInt(end.format('D'), 10),
      month: parseInt(end.format('MM'), 10),
      year: parseInt(end.format('YYYY'), 10),
    };
  }
}
