import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class universoService {
  displayedColumns: string[] = ['nombre', 'descripcion', 'estatus', 'opciones'];
  constructor(private _http: HttpClient) { }
  
  async getUniverses(){
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + "/universe")
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
    return promise;
  }
  async getBrands(){
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + "/brands/all")
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
    return promise;
  }
  async createUniverse(name){
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + "/universe" , {name:name})
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
    return promise;
  }
  async createBrand(name, universe, code ,){
    let promise = new Promise((resolve, reject) => {
      this._http
        .post(environment.API_URL + "/brands" , {name:name , universe: universe, code:code})
        .toPromise()
        .then(async (res : any) => {
          console.log(res);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
    return promise;

    
  }
  async updateBrand(id, name, universe, code ,){
    let promise = new Promise((resolve, reject) => {
      this._http
        .put(environment.API_URL + "/brands/"+id , {name:name , universe: universe, code:code})
        .toPromise()
        .then(async (res : any) => {
          console.log(res);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
    return promise;

    
  }
  subirArchivo( archivo  , id ) {
    console.log('Subir archivo' , archivo , id);
    let promise = new Promise((resolve, reject) => {
      this._http
        .put(environment.API_URL + '/brands/upload/'+ id , archivo )
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }
  deleteBrand(id){
    let promise = new Promise((resolve, reject) => {
      this._http
        .delete(environment.API_URL + "/brands/"+id )
        .toPromise()
        .then(async (res : any) => {
          console.log(res);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
    return promise;
  }
}
