import { BqAuthService } from '../services/bq-auth.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminApiService } from '../admin-api.service';

@Component({
  selector: 'app-npspromotion-list',
  templateUrl: './npspromotion-list.component.html',
  styleUrls: ['./npspromotion-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NpspromotionListComponent implements OnInit {
  public _promotionList: any;
  public _isDeveloper: boolean;

  constructor(
    private adminApi: AdminApiService,
    private bqAuth: BqAuthService,
  ) {}

  ngOnInit() {
    this._isDeveloper = this.bqAuth.isDeveloper();

    this.loadPromotions();
  }

  loadPromotions() {
    this.adminApi.listNPSPromotions().then((promotions: any) => {
      this._promotionList = promotions;
    });
  }
}
