import { Component, OnInit } from '@angular/core';
import { divisionsService } from '../services/divisions/divisions.service'
@Component({
  selector: 'app-divisiones',
  templateUrl: './divisiones.component.html',
  styleUrls: ['./divisiones.component.scss']
})
export class DivisionesComponent implements OnInit {

  constructor(public _divisionsService: divisionsService) { }

  ngOnInit() {
  }

}
