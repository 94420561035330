import { BqAuthService } from "../services/bq-auth.service";
import { ToastrService } from "ngx-toastr";
import { AdminApiService } from "./../admin-api.service";
import {
  ViewContainerRef,
  Component,
  OnInit,
  ViewEncapsulation,
  EventEmitter,
  Output
} from "@angular/core";

import { Router } from "@angular/router";

@Component({
  selector: "app-everest-generate",
  templateUrl: "./everest-generate.component.html",
  styleUrls: ["./everest-generate.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class EverestGenerateComponent implements OnInit {
  public _profiles: any;
  public _stores: any;
  public _categories: any;

  public _currentEverest: any;
  public _errors: any;
  public _isDeveloper: boolean;

  @Output()
  onCreationEnd = new EventEmitter<boolean>();

  constructor(
    private adminApi: AdminApiService,
    private router: Router,
    public toastr: ToastrService,
    private bqAuth: BqAuthService,
    vcr: ViewContainerRef
  ) {
    this._profiles = [];
    this._stores = [];
    this._categories = [];
    this._isDeveloper = false;
    this._currentEverest = {
      stores: [],
      profiles: [],
      name: "",
      start: "startNow",
      categories: []
    };

    this._errors = {
      name: false,
      start: false,
      profile: false,
      stores: false,
      categories: false
    };
  }

  ngOnInit() {
    this._isDeveloper = this.bqAuth.isDeveloper();
    this.loadData();
  }

  loadData() {
    this.adminApi.getEverestProfiles().then(profiles => {
      this._profiles = profiles;
    });

    this.adminApi.listStoresSelect().then((stores: any) => {
      this._stores = stores.items;
    });

    this.adminApi.getStoreCategories().subscribe(data => {
      this._categories = data;
    });
  }

  selectAllStores() {
    this._currentEverest.stores = [];

    for (let store of this._stores) {
      this._currentEverest.stores.push(store._id);
    }
  }

  deselectAllStores() {
    this._currentEverest.stores = [];
  }

  selectAllProfiles() {
    this._currentEverest.profiles = [];

    for (let profile of this._profiles) {
      this._currentEverest.profiles.push(profile._id);
    }
  }
  deselectAllProfiles() {
    this._currentEverest.profiles = [];
  }

  generateEverest() {
    this._errors.stores = this._currentEverest.stores.length <= 0;
    this._errors.profile = this._currentEverest.profiles.length <= 0;
    this._errors.categories = this._currentEverest.categories.length <= 0;
    this._errors.name =
      !this._currentEverest.name || this._currentEverest.name === "";
    this._errors.start =
      this._currentEverest.start !== "start" &&
      this._currentEverest.start !== "startNow" &&
      this._currentEverest.sttart !== "draft";

    if (
      this._errors.start ||
      this._errors.profiles ||
      this._errors.stores ||
      this._errors.name
    ) {
      this.toastr.error(
        "Hay errores en el formulario, corrigalos y vuelva a intentarlo!"
      );
      return;
    }

    this.adminApi
      .createEverestCampaign(this._currentEverest)
      .then(res => {
        this.toastr.success("Se ha generado la nueva campaña.");
        this.onCreationEnd.emit(true);
      })
      .catch(err => {
        this.toastr.error(
          "Ha habido un error al generar la campaña, recargue la pagina y vuelva a intentarlo."
        );
        return;
      });
  }
}
