import { StoreApiService } from '../services/store-api.service';
import { ToastrService } from 'ngx-toastr';
import { AdminApiService } from './../admin-api.service';
import * as _ from 'underscore';

import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
  Output,
  EventEmitter,
  Renderer2,
} from '@angular/core';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-admin-user-edit',
  templateUrl: './admin-user-edit.component.html',
  styleUrls: ['./admin-user-edit.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminUserEditComponent implements OnInit, OnChanges {
  public initialAllowedStores: any;
  public allStores: any;
  public divisionsStoresMarked: any;
  public toDelAllowedStoresValues: any[] = new Array();
  public toAddAllowedStoresValues: any[] = new Array();
  public initialAllowedStoresValues: any[] = new Array();
  public divisionList: any;
  public currentUser: any;
  public groups: any;
  public toDelStores: any;
  public allowedStores: any;
  public divisionsStores: any;

  public mainDivisionText: string;
  public mainStoreText: string;

  public __isDeveloper: boolean;

  private _userId: string;

  @Output()
  onEditEnd = new EventEmitter<boolean>();

  @Input()
  set userId(userId: string) {
    this._userId = userId;
  }
  get userId(): string {
    return this._userId;
  }

  constructor(
    private adminApi: AdminApiService,
    private storeApi: StoreApiService,
    public toastr: ToastrService,
    private renderer: Renderer2,
  ) {
    this.__isDeveloper = false;
  }

  ngOnInit() {
    this.currentUser = {};
    this.initialAllowedStores = true;
    // GET arrays user
    this.adminApi.listGroups().then(res => (this.groups = res));
    // this.adminApi
    //   .listAllowedStores(this._userId)
    //   .then(res => (this.allowedStores = res));
    this.adminApi.listAllStores().then((res: any) => {
      this.allStores = res.items;

      if (this.currentUser.onlyStore) {
        const store = _.find(this.allStores, {
          _id: this.currentUser.mainStore,
        });

        if (store) {
          this.mainStoreText = store.name;
        }
      }
    });

    this.adminApi.listStoreDivisions().subscribe(data => {
      this.divisionsStores = data;
    });
    this.adminApi.getStoreDivisions().subscribe(data => {
      this.divisionList = data;
      if (this.currentUser.isRegional) {
        const division = _.find(this.divisionList, {
          _id: this.currentUser.mainDivision,
        });

        if (division) {
          this.mainDivisionText = division.name;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (parseInt(this._userId, 10) === -1) {
      // console.debug("Creating new user"); // tslint-disable-line
    } else {
      this.adminApi.userDetail(this._userId).then((res: any) => {
        let user = res;
        let groups = user.groups;

        user.groups = [];
        _.each(groups, gr => {
          user.groups.push(gr._id);
        });

        user.name = res.name || '';
        user.surname = res.surname || '';
        user.username = res.username || '';
        user.email = res.email || '';
        user.jobTitle = res.jobTitle || '';
        user.groups = res.groups || [];
        user.isAdmin = res.isAdmin || false;
        user.isDeveloper = res.isDeveloper || false;
        user.active = res.active || false;
        user.banned = res.banned || false;
        user.aclReportsDownload = res.aclReportsDownload || false;

        user.allowedStores = res.allowedStores || [];

        if (user.isRegional) {
          const division = _.find(this.divisionList, {
            _id: user.mainDivision,
          });

          if (division) {
            this.mainDivisionText = division.name;
          }
        }

        if (user.onlyStore) {
          const store = _.find(this.allStores, {
            _id: user.mainStore,
          });

          if (store) {
            this.mainStoreText = store.name;
          }
        }

        this.currentUser = user;
      });
    }
  }

  goBack() {
    this.onEditEnd.emit(false);
  }

  generatePassword() {
    this.adminApi
      .generateUserPassword(this._userId)
      .then(res => {
        this.toastr.success(
          'Se ha generado un password aleatorio para el usuario y se ha enviado por correo.',
        );
        console.dir(res);
      })
      .catch(err => {
        this.toastr.error(
          'Ha habido un error al generar una nueva contraseña, recargue la pagina y vuelva a intentarlo, si el error persiste contacte con el soporte técnico',
        );
        console.dir(err);
      });
  }

  deepIndexOf(arr, obj) {
    return arr.findIndex(function(cur) {
      return Object.keys(obj).every(function(key) {
        return obj[key] === cur[key];
      });
    });
  }

  addStore(store_id, store_name) {
    let indexToAdd = this.deepIndexOf(this.toDelAllowedStoresValues, {
      _id: store_id,
    });
    let indexActual = this.deepIndexOf(this.allowedStores, { _id: store_id });

    if (indexToAdd === -1 && indexActual === -1) {
      this.allowedStores.push({ _id: store_id, name: store_name });
      this.toastr.success('Tienda añadida correctamente');
    } else if (indexToAdd !== -1) {
      this.toastr.error(
        'No se ha podido añadir',
        'La tienda seleccionada está marcada para ser borrrada',
      );
    } else {
      this.toastr.error('La tienda seleccionada ya está añadida');
    }
  }

  rmStoreFromArray() {
    for (let d = 0; d < this.toDelAllowedStoresValues.length; d++) {
      let index = this.deepIndexOf(this.allowedStores, {
        _id: this.toDelAllowedStoresValues[d]._id,
      });
      if (index !== -1) {
        this.allowedStores.splice(index, 1);
      }
    }
    this.toDelAllowedStoresValues = new Array();
  }

  rmStore(store_id, store_name) {
    let index = this.deepIndexOf(this.toDelAllowedStoresValues, {
      _id: store_id,
    });
    if (index !== -1) {
      this.toDelAllowedStoresValues.splice(index, 1);
    } else {
      this.toDelAllowedStoresValues.push({ _id: store_id, name: store_name });
    }
    console.log(this.toDelAllowedStoresValues);
  }

  addDivisionStores(division) {
    this.adminApi.listDivisionsStores(division._id).then((res: any) =>
      res.forEach(element => {
        let index = this.deepIndexOf(this.allowedStores, { _id: element._id });
        if (index !== -1) {
          this.toastr.warning(
            'La tienda ' + element.name + ' ya estaba asignada',
          );
        } else {
          this.allowedStores.push({ _id: element._id, name: element.name });
        }
      }),
    );

    this.toastr.success(
      'Todas las tiendas de la región ' +
        division.name.toUpperCase() +
        ' han sido añadidas correctamente',
    );
  }

  updateUser() {
    console.log('Updating user...');
    let user = this.currentUser;

    if (!user.username || !user.name || !user.email) {
      this.toastr.error(
        'Debe rellenar los campos obligatorios para poder editar el usuario.',
      );
      return;
    }

    if (user.groups.length <= 0) {
      this.toastr.error('Debe seleccionar al menos un grupo para el usuario');
      return;
    }

    // user.allowedStores = this.allowedStores;
    if (user.mainDivision && !user.isRegional) {
      this.toastr.warning(
        'Ha asignado una región pero no la ha añadido, pulse el botón de Asignar región para añadirla.',
      );
      return;
    }

    if (user.isRegional) {
      if (!user.mainDivision) {
        this.toastr.error(
          'Debe seleccionar una región para asignar al usuario',
        );
        return;
      }
    }

    if (user.onlyStore) {
      if (!user.mainStore) {
        this.toastr.error('Debe seleccionar una tienda para poder asignarla.');
        return;
      }
    }

    this.adminApi
      .editUser(this._userId, user)
      .then(res => {
        this.toastr.success('Se ha actualizado la información del usuario.');
        this.onEditEnd.emit(true);
      })
      .catch(err => {
        this.toastr.error('Ha habido un error al guardar el ususario.');
        return;
      });
  }

  selectStore() {
    if (!this.currentUser.mainStore) {
      this.toastr.error('Debe seleccionar una tienda para poder asignarla!');
      return;
    }

    this.currentUser.onlyStore = true;

    const store = _.find(this.allStores, {
      _id: this.currentUser.mainStore,
    });

    if (store) {
      this.mainStoreText = store.name;
    }

    if (this.currentUser.mainDivision) {
      this.removeRegion();
    }
  }

  removeStore() {
    this.currentUser.onlyStore = false;
    this.currentUser.mainStore = null;
  }

  selectRegion() {
    if (!this.currentUser.mainDivision) {
      this.toastr.error('Debe seleccionar una región para poder asignarla!');
      return;
    }

    this.currentUser.isRegional = true;

    const division = _.find(this.divisionList, {
      _id: this.currentUser.mainDivision,
    });

    if (division) {
      this.mainDivisionText = division.name;
    }

    if (this.currentUser.mainStore) {
      this.removeStore();
    }
  }

  removeRegion() {
    this.currentUser.isRegional = false;
    this.currentUser.mainDivision = null;
  }

  createUser() {
    const tUser = this.currentUser;

    if (!tUser.username || !tUser.name || !tUser.email) {
      this.toastr.error(
        'Debe rellenar los campos obligatorios para poder editar el usuario.',
      );
      return;
    }

    if (tUser.groups.length <= 0) {
      this.toastr.error('Debe seleccionar al menos un grupo para el usuario');
      return;
    }

    let nUser = {
      username: tUser.username.toLowerCase(),
      name: tUser.name,
      surname: tUser.surname,
      email: tUser.email,
      groups: tUser.groups,
      isAdmin: tUser.isAdmin,
      isDeveloper: tUser.isDeveloper,
      active: tUser.active,
      banned: tUser.banned,
      jobTitle: tUser.jobTitle || '',
      isRegional: false,
      mainDivision: null,
      onlyStore: false,
      mainStore: null,
    };

    if (tUser.mainDivision && !tUser.isRegional) {
      this.toastr.warning(
        'Ha asignado una región pero no la ha añadido, pulse el botón de Asignar región para añadirla.',
      );
      return;
    }

    if (tUser.isRegional) {
      if (!tUser.mainDivision) {
        this.toastr.error(
          'Debe seleccionar una región para asignar al usuario',
        );
        return;
      }
      nUser.isRegional = true;
      nUser.mainDivision = tUser.mainDivision;
    }

    if (tUser.onlyStore) {
      if (!tUser.mainStore) {
        this.toastr.error('Debe seleccionar una tienda para poder asignarla!');
        return;
      }

      nUser.onlyStore = true;
      nUser.mainStore = tUser.mainStore;
    }

    this.adminApi
      .newUser(nUser)
      .toPromise()
      .then(r => {
        this.toastr.success('Se ha creado el nuevo usuario!');
        setTimeout(() => {
          this.goBack();
        }, 300);
      })
      .catch(e => {
        if (e.error) {
          this.toastr.error(e.error);
        }
      });
  }
}
