import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";

@Injectable()
export class StoreApiService {
  constructor(private _http: HttpClient) {}

  getStoreList() {
    let promise = new Promise((resolve, reject) => {
      this._http
        .get(environment.API_URL + "/stores/list")
        .toPromise()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    return promise;
  }
}
