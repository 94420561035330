import { BqAuthService } from '../services/bq-auth.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminApiService } from '../admin-api.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardPageComponent implements OnInit {
  public _isDeveloper: boolean;

  constructor(private bqAuth: BqAuthService) {
    this._isDeveloper = false;
  }

  ngOnInit() {
    this._isDeveloper = this.bqAuth.isDeveloper();
  }
}
