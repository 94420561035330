import { ToastrService } from "ngx-toastr";
import { CommonApiService } from "../services/common-api.service";
import { UserApiService } from "../services/user-api.service";
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewContainerRef
} from "@angular/core";
import { User } from "../services/bqdata-model";

@Component({
  selector: "app-user-profile-page",
  templateUrl: "./user-profile-page.component.html",
  styleUrls: ["./user-profile-page.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class UserProfilePageComponent implements OnInit {
  public _userProfile: User;
  public _showChangePassword: boolean;
  public _profileChanged: boolean;
  public _minPasswordLength: number;
  public _chgPassword: any;

  constructor(
    private userApi: UserApiService,
    private commonApi: CommonApiService,
    public toastr: ToastrService,
    vcr: ViewContainerRef
  ) {}

  ngOnInit() {
    this.loadProfile();
    this._userProfile = new User();
    this._showChangePassword = false;
    this._minPasswordLength = this.commonApi.getAppConfig().minPassLength;
    this._profileChanged = false;

    this._chgPassword = {
      old: "",
      new: "",
      check: ""
    };
  }

  showChangePassword() {
    this._showChangePassword = true;
  }

  generateRandomPassword() {
    this.userApi
      .generateRandomPassword()
      .then(res => {
        this._showChangePassword = false;
        this.toastr.success(
          "En breve recibirá un correo electrónico con su nueva contraseña."
        );
      })
      .catch(err => {
        this.toastr.error(
          "Ha habido un error al generar la contraseña aleatoria, vuelva a probar o utilice una contraseña a medida."
        );
        console.dir(err);
      });
  }

  updateProfilePassword() {
    this.userApi
      .updatePassword(this._chgPassword.old, this._chgPassword.check)
      .then((result: any) => {
        if (result.success) {
          this._showChangePassword = false;
          this.toastr.success(
            "Se han cambiado correctamente sus credenciales, en su proximo inicio debera usar su nueva contraseña."
          );
        } else {
          this.toastr.warning(
            "Lo sentimos pero la contraseña anterior no es correcta!"
          );
        }
      })
      .catch(err => {
        this.toastr.error(
          "Ha habido un error al cambiar la contraseña, vuelva a probar o utilice una contraseña aleatoria."
        );
        console.dir(err);
      });
  }

  saveProfile() {
    if (!this._userProfile.name) {
      this.toastr.error("El nombre el obligatorio.");
      return;
    }

    if (!this._userProfile.email) {
      this.toastr.error("El correo electronico el obligatorio.");
      return;
    }

    this.userApi
      .updateProfile(this._userProfile)
      .then(res => {
        console.dir(res);
      })
      .catch(err => {
        console.dir(err);
      });
  }

  loadProfile() {
    this.userApi
      .profile()
      .then((profile: User) => {
        this._userProfile = profile;
      })
      .catch(err => {});
  }
}
