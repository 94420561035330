import { AdminApiService } from "./../admin-api.service";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ViewContainerRef } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { debug } from "util";

@Component({
  selector: 'app-upload-happy',
  templateUrl: './upload-happy.component.html',
  styleUrls: ['./upload-happy.component.scss']
})
export class UploadHappyComponent implements OnInit {
  public currentFile: any;
  public __sending: boolean;
  constructor(
    public toastr: ToastrService,
    vcr: ViewContainerRef,
    private adminApi: AdminApiService
  ) { }

  ngOnInit() {
    this.__sending = false;
  }
  uploadCSV(ev) {
    this.__sending = true;
    if (!ev || !ev.target || !ev.target.files[0]) {
      this.toastr.error("Debe seleccionar un fichero.");
      return;
    }

    console.log("Uploading CSV %c%s", "color: green", ev.target.files[0].name);
    let reader = new FileReader();
    let file = ev.target.files[0];
    console.log(file);
    reader.readAsDataURL(file);
    reader.onload = () => {
      // let tempFile = {
      //   filename: file.name,
      //   type: file.type,
      //   data: reader.result.split(",")[1]
      // };

      let formData: FormData = new FormData();
      formData.append("csvfile", ev.target.files[0] , file.name);
      console.log(formData);
      this.adminApi
        .uploadHPCSV(formData)
        .then(res => {
          this.toastr.success(
            "El fichero se ha subido correctamente, en cuanto haya sido procesado se mostrará en la pantalla de NPS."
          );
        })
        .catch(err => {
          this.toastr.error(err, "Ha habido un error al subir el archivo.");
        });
    };
  }

}
