import { Component, OnInit } from '@angular/core';
import { universoService } from '../services/universos/universo.service';
import { ToastrService } from "ngx-toastr";
import  { Router } from '@angular/router'
@Component({
  selector: 'app-add-brands',
  templateUrl: './add-brands.component.html',
  styleUrls: ['./add-brands.component.scss']
})
export class AddBrandsComponent implements OnInit {
  list;
  name;
  code;
  universo;
  logo;
  formData ;
  constructor(   private universe: universoService, public toastr: ToastrService, public router: Router) { }

  ngOnInit() {
    this.universe.getUniverses().then( res =>{
      console.log(res);
      this.list = res;
    })
  }
  create(){
    console.log(this.name , this.code , this.universo , this.logo);
    let c = document.getElementById('file');
    this.logo = document.getElementById('file'); 
    if(!this.name || !this.code && !this.universo && !this.logo){
      this.toastr.error( "Complete todos los campos");
    }else{
      this.universe.createBrand(this.name ,this.universo ,  this.code ).then((res:any) =>{
        console.log(res);
        this.upload(this.formData , res.marca._id);
      })
    }
  }
  preUpload(ev){
    console.log(ev);
    this.formData = ev;
    // let reader = new FileReader();
    // let file = ev.target.files[0];
    // reader.readAsDataURL(file);
    // reader.onload = () => { 
    //   let tempFile = {
    //     filename: file.name,
    //     type: file.type,
    //     data: reader.result.split(",")[1]
    //   };
    // }
    // let formData: FormData = new FormData();
    // // this.formData = formData;
    // formData.append("imagen", file, file.name);
    // console.log(formData);
  }
  upload(ev , id){
    // this.__sending = true;
    if (!ev || !ev.target || !ev.target.files[0]) {
      this.toastr.error("Debe seleccionar un fichero.");
      return;
    }

    console.log("Uploading CSV %c%s", "color: green", ev.target.files[0].name);

    // if (ev.target.files[0].type !== 'text/csv') {
    //   console.log(
    //     'Error uploading CSV, is not a CSV current file type: %c%s',
    //     'color: red',
    //     ev.target.files[0].type
    //   );
    //   this.toastr.error(
    //     'El fichero debe ser del tipo CSV, entre en Excel y exportelo en el formato correcto.'
    //   );
    //   return;
    // }

    let reader = new FileReader();
    let file = ev.target.files[0];
    console.log(file);
    reader.readAsDataURL(file);
    reader.onload = () => { 
      let tempFile = {
        filename: file.name,
        type: file.type,
        data: reader.result.split(",")[1]
      };

      let formData: FormData = new FormData();

      formData.append("logo", file, file.name);
      console.log(formData);
      this.universe.subirArchivo(formData , id)
        .then(res => {
          this.toastr.success(
            "El fichero se ha subido correctamente, en cuanto haya sido procesado se mostrará en la pantalla de NPS."
          );
          this.router.navigate(["/marcas"]);
        })
        .catch(err => {
          this.toastr.error(err, "Ha habido un error al subir el archivo.");
        });
    };
  }
}
