import { Component, OnInit } from '@angular/core';
import { GroupService } from '../services/groups/group.service';
// import {FormBuilder,  Validators , FormGroup} from '@angular/forms';
@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  list;
  form = { nombre:'' , descripcion: '' , internalName: '' ,estatus: true };
  constructor(
    public _groupService: GroupService,
    // private fb: FormBuilder,
  ) { 
    
  }

  ngOnInit() {
    this.form = { nombre:'' , descripcion: '' , internalName: '' , estatus: true };
    this._groupService.getAllGroups().then(res =>{
      console.log(res);
      this.list = res;
    })
  }
  
  edit(element){
    console.log('el' , element);
  }

}
