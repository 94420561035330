import { BqAuthService } from "./services/bq-auth.service";
import { Component } from "@angular/core";
import { CommonApiService } from "./services/common-api.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  title = "app";

  constructor(
    private bqAuth: BqAuthService,
    private commonApi: CommonApiService
  ) {
    console.log("App initialized!");
    this.commonApi.startApp().then(config => {
      console.log("Configuration received!");
    });
  }
}
