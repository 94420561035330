import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GroupService } from '../services/groups/group.service';
@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit {
  form = { nombre:'' , descripcion: '' , internalName: '' , estatus: true , divisions: [] , stores: [] };
  divisiones;
  stores;
  addDivisiones = [];
  addStores = [];
  constructor( public _groupService: GroupService  , public _router: Router) { }

  async ngOnInit() {
    await this._groupService.getDivisions().then(  (res : any) =>{
      console.log(res);
      for(let r of res){
        r.checked =this.validDivisions(r._id);
      }
      this.divisiones = res;
    })
    await this._groupService.getStores().then( async (res : any) =>{
      // console.log(res);
      for(let r of res){
        r.checked = await this.validStores(r._id);
      }
      this.stores = res;
      console.log(this.stores);
    })
  }
  create(){
    console.log('guardar');
    console.log(this.form);
    if(this.form.nombre == '' || this.form.descripcion == '' || this.form.internalName == ''){
      console.log('algo esta mal');
    }else{
      this.form.divisions = this.addDivisiones;
      this.form.stores = this.addStores;
      console.log('Valido para crear');
      this._groupService.createGroup(this.form).then( res => {
        console.log(res); 
        // this.ngOnInit();  
        this._router.navigate(['groups'])     
      })
      .catch(err => {
        console.log(err);
      })
    }
  }
  async validStores(_valor) {
    // console.log(_valor);
    let x = await new Promise ((resolve , reject) => {
      if(this.addStores.length > 0) {
        let aux;
        let i = 0;
        for(let lct of this.addStores){
          i++;
          if(lct == _valor){
            console.log('igual');
            aux = true;
            resolve(aux);
          }else{
            aux = false;
            if(i == this.addStores.length){
              resolve(aux);
            }
          }   
        }
        
      } else {
        // flag= false;
        resolve(false);
      }
    });
    // debugger;
    return x;
    
  }
  async validDivisions(_valor) {
    // console.log(_valor);
    let x = await new Promise ((resolve , reject) => {
      if(this.addDivisiones.length > 0) {
        let aux;
        let i = 0;
        for(let lct of this.addDivisiones){
          i++;
          if(lct == _valor){
            console.log('igual');
            aux = true;
            resolve(aux);
          }else{
            aux = false;
            if(i == this.addDivisiones.length){
              resolve(aux);
            }
          }   
        }
        
      } else {
        // flag= false;
        resolve(false);
      }
    });
    // debugger;
    return x;
    
  }
  async check(sel){
    console.log(sel.srcElement.checked);
    if(sel.srcElement.checked == true){
      await this.addDivision(sel.srcElement.value).then(res =>{
        console.log(this.addDivisiones);
      })
    }else{
      await this.removeDivision(sel.srcElement.value).then(res =>{
        console.log(this.addDivisiones);
      })
    }
  }
  async checkStore(sel){
    console.log(sel.srcElement.checked);
    if(sel.srcElement.checked == true){
      await this.addStore(sel.srcElement.value).then(res =>{
        console.log(this.addStores);
      })
    }else{
      await this.removeStore(sel.srcElement.value).then(res =>{
        console.log(this.addStores);
      })
    }
  }
  async addStore(_valor){
    return new Promise ( async (resolve, rejected) =>{
      await this.validStore(_valor).then(res =>{
        console.log(res);
        if(res == false){
          this.addStores.push(_valor);
          resolve({ok:true});
        }
      })
    })
  }
  async addDivision(_valor){
    return new Promise ( async (resolve, rejected) =>{
      await this.validDivisions(_valor).then(res =>{
        console.log(res);
        if(res == false){
          this.addDivisiones.push(_valor);
          resolve({ok:true});
        }
      })
    })
  }
  async removeStore(_valor){
    return  new Promise ( async (resolve, rejected) =>{
      await this.validStore(_valor).then(res =>{
        console.log(res);
        if(res != false){
          const index = this.addStores.indexOf(_valor, 0);
          if (index > -1) {
            this.addStores.splice(index, 1);
          }
          resolve({ok:true})
        }
      });
    });
  }
  async removeDivision(_valor){
    return  new Promise ( async (resolve, rejected) =>{
      await this.validDivisions(_valor).then(res =>{
        console.log(res);
        if(res != false){
          const index = this.addDivisiones.indexOf(_valor, 0);
          if (index > -1) {
            this.addDivisiones.splice(index, 1);
          }
          resolve({ok:true})
        }
      });
    });
  }
  async validStore(_valor) {
    // console.log(_valor);
    let x = await new Promise ((resolve , reject) => {
      if(this.addStores.length > 0) {
        let aux;
        let i = 0;
        for(let lct of this.addStores){
          i++;
          if(lct == _valor){
            console.log('igual');
            aux = true;
            resolve(aux);
          }else{
            aux = false;
            if(i == this.addStores.length){
              resolve(aux);
            }
          }   
        }
        
      } else {
        // flag= false;
        resolve(false);
      }
    });
    // debugger;
    return x;
    
  }
}
