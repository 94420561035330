import { ToastrService } from "ngx-toastr";
import { AdminApiService } from "./../admin-api.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewContainerRef
} from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { MomentModule } from "angular2-moment";
import { BqAuthService } from "../services/bq-auth.service";

@Component({
  selector: "app-npspromotion-edit-page",
  templateUrl: "./npspromotion-edit-page.component.html",
  styleUrls: ["./npspromotion-edit-page.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class NpspromotionEditPageComponent implements OnInit {
  public __promoId: any;
  public __promotion: any;
  public _isDeveloper: boolean;
  public __blockInteraction: boolean;
  public _requestedStartDate: any;
  public _activePromotion: any;

  public __currentTraceEmailId: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private adminApi: AdminApiService,
    private modalService: NgbModal,
    public toastr: ToastrService,
    private _bqAuth: BqAuthService
  ) {
    this._requestedStartDate = {
      day: null,
      month: null,
      year: null
    };
    this.__blockInteraction = false;
    this.__currentTraceEmailId = null;
    this._isDeveloper = this._bqAuth.isDeveloper();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params["promo_id"] != null) {
        this.__promoId = params["promo_id"];
        this.loadPromotion();
      }
    });
  }

  traceEmail(surveyId: string) {
    this.__currentTraceEmailId = surveyId;
  }

  endTraceEmail() {
    this.__currentTraceEmailId = null;
  }

  loadPromotion() {
    this.adminApi
      .loadNPSPromotion(this.__promoId)
      .then(promo => {
        this.__promotion = promo;

        this.adminApi
          .getActiveNPS()
          .then(aPromo => {
            this._activePromotion = aPromo;
          })
          .catch(aErr => {});
      })
      .catch(err => {
        console.dir(err);
      });
  }

  open(content) {
    this.modalService.open(content).result.then(
      result => {
        this.__blockInteraction = true;
        this.checkSchedule(result);
      },
      reason => {}
    );
  }

  checkSchedule(date: any) {
    let tStart = moment(
      date.year + "-" + date.month + "-" + date.day,
      "YYYY-M-D"
    );
    let today = moment();
    let minStart = moment().add(2, "d");

    // if (tStart <= today || tStart < minStart) {
    //   this.toastr.error(
    //     'Debe especificar una fecha superior al día ' +
    //       minStart.format('DD/MM/YYYY')
    //   );
    //   this.__blockInteraction = false;
    //   return;
    // }

    this.adminApi
      .scheduleNPSPromotion(this.__promoId, tStart.toISOString(true))
      .then(res => {
        this.__blockInteraction = false;
        this.toastr.success("Se ha programado la campaña!");
        this.loadPromotion();
      })
      .catch(err => {
        this.toastr.error(err, "Ha habido un error al programar la campaña");
        this.__blockInteraction = false;
        console.dir(err);
      });
  }

  validatePromotion(alertModal) {
    this.modalService.open(alertModal).result.then(result => {
      this.adminApi
        .validateNPSPromotion(this.__promoId)
        .then(() => {
          this.toastr.success(
            "La promoción se ha enviado a la cola de validación!"
          );
          setTimeout(() => {
            this.loadPromotion();
          }, 500);
        })
        .catch(err => {
          this.toastr.error(
            err,
            "Ha habido un error al mandar la campaña a la cola de validación"
          );
        });
    });
  }

  forceClose() {
    this.adminApi.developerForcePromoClose(this.__promoId).then(() => {
      this.toastr.info(
        "Se está invalidando en background, recuerda que es una funcion de desarrollo!"
      );
    });
  }
}
