import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";

@Injectable()
export class CommonApiService {
  constructor(private _http: HttpClient) {}

  startApp() {
    let promise = new Promise((resolve, reject) => {
      let appConfig = sessionStorage.getItem("BQT.environment");

      if (!appConfig) {
        this._http
          .get(environment.API_URL + "/start")
          .toPromise()
          .then(res => {
            sessionStorage.setItem("BQT.environment", JSON.stringify(res));
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      } else {
        resolve(JSON.parse(appConfig));
      }
    });

    return promise;
  }

  getAppConfig() {
    let appConfig = sessionStorage.getItem("BQT.environment");
    if (!appConfig) {
      console.error("appConfig has not been loaded yet!");
    }

    return JSON.parse(appConfig || "{}");
  }
}
